@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400&display=swap');
body {
  /* padding-top: 5.5em; */
  overflow-x: hidden;
  color: #FFF;
  background-color:#000 !important;
  height: auto;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

main { 
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#2a7fff+0,dddada+90 */
  background: #000000; /* Old browsers */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content-main-padding {
  padding: 4.5em 11%;
}

.MuiMobileStepper-dot {
  background-color: #fff;
}

a {
  text-decoration: none;
  color: red;
}

a.current, a:hover {
  color: #70EADC;
}

.content-photo-grid-padding {
  padding: 3em 1em 3em 3em;
}

.content-video-grid-padding {
  padding: 3em 1em 3em 1em;
}

.article-main-padding {
  padding: 0.1em 0;
}

.article-item.clickable {
  cursor: pointer;
}

.article-item:hover {
  background-color: #ececec;
}

.article-item:hover .article-link {
  color: #70EADC;
}

.article-item-photo {
  transition: all 0.2s ease-in;
}

.article-item:hover .article-item-photo {
  transform: scale(1.025);
}

.articles-page .MuiInputBase-input, .videos-page .MuiInputBase-input{
  border: 1px solid #70EADC;
  background-color: #0a0a0a;
  color: #FFF;
}

.appbarlink {
    text-decoration: none;
    color: #fff;
    margin: 0 1em;
    letter-spacing: 1px;
    font-family: 'Poppins';
    font-weight: bold;
    font-size: 1.2em;
}

.video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.product-item {
    color: #fff;
    padding: 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    cursor: pointer;
    background-color:teal;
}

.footer-logo {
  padding: 0px 3em !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-multiline.MuiOutlinedInput-multiline.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
  background-color: #fff;
  color: #000;
}

.product-item:hover {
  background-color: #70EADC;
}

.article-item-readmore {
  color: #70EADC;
  font-weight: 600;
  padding-top: 0.5em;
}

.article-item:hover {
  background-color: #70EADC;
}

.article-item:hover .MuiTypography-root {
  color: #fff;
}

.product-item-img {
  height: 120px;
  transition: all 0.2s ease-in;
}

.product-item:hover .product-item-img {
  transform: scale(1.05);
}

.grid-layout-item {
  width: 100%;
  height: 100%;
}

.grid-layout-form {
  height: 100%;
  padding: 2em 1.5em;
  overflow: auto;
}


.navbar-profile-name, .navbar-profile-moble-name {
  font-family: 'Poppins';
  color: #fff;
  padding: 0 0.75em;
}

.requirements-confirmed {
  background-color: #388e3c !important;
}

/* .requirements-confirmed .MuiTypography-root,
.requirements-pending .MuiTypography-root,
.requirements-pending .MuiInputBase-input,
.requirements-confirmed .MuiInputBase-input{
  color: #fff
} */

.MuiInputBase-input.Mui-disabled {
  color: #707070;
}


.requirements-pending {
  background-color: #ff4c3f !important;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.banner-item-button.MuiButton-containedPrimary{
  background-color: rgba(4, 153, 210, 0.7);
    border-width: 4px;
    border-style: solid;
    border-radius: 15px;
    border-color: #FFF;
    font-size: x-large;
    width: 20%;
}

a.appbarlink.current {
  text-decoration: underline;
  text-decoration-thickness: 5px;
  color: #FFF;
}

.blog p.MuiTypography-root.MuiTypography-body1 {
  display: -webkit-box;
  overflow:hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}



.button-default {
  font-size: 1.5em !important;
}

.content-main-padding.blog {
  padding: 4em 11% 0em;
}

.manrope-font {
  font-family: 'Manrope', sans-serif !important;
}

.boldstrom-font {
  font-family: 'Boldstorm' !important;
}

.uniform-style1 {
  font-size: 0.9375rem !important;
  text-transform: uppercase !important;
}

/* @media screen and (min-width: 600px) {
  .app-toolbar {
     padding-left: 30px;
     padding-right: 30px;
  }
} */

img {
  max-width: 100%;
}


.appbar-drawer {
  width: 30%;
}

.join-us-container{
  position:relative;
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.join-us-content{
  vertical-align: middle;
}

.overlay-aboutUS {
  position:relative;
}
 
.overlay-aboutUS::before {
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  background: -moz-linear-gradient(left, rgba(125,126,125,0) 0%, rgba(14,14,14,1) 100%);
  background: -webkit-linear-gradient(left, rgba(125,126,125,0) 0%,rgba(14,14,14,1) 100%);
  background: linear-gradient(to right, rgba(125,126,125,0) 0%,rgba(0,0,0,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007d7e7d', endColorstr='#0e0e0e',GradientType=1 );
}

body p, h1, h2, h3, h4, h5, h6 {
  color: #FFF;
  font-family: 'Poppins', sans-serif !important;
}

.search-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 1em 0;
}

.search-inner {
  min-width: 350px;
}

.speech-bubble {
  position: relative;
  background: #000000;
  padding: 0.5em 1em;
  border: 1px solid #fff;
  border-radius: 0.4em;
}

/* .speech-bubble:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 0;
	height: 0;
	border: 15px dotted transparent;
	border-top-color: #fff;
	border-bottom: 0;
	margin-left: -135px;
	margin-bottom: -15px;
} */

.apply-now-label {
  position:relative;
}

.apply-now-label .apply-now-inner-container{
  position:absolute;
  right:0px;
  top:11em;
}

.table-blog p{
  color:#FFf;
}

.table-blog tr{
  padding-bottom:1em;
  border-bottom:1px solid #fff;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.button-default.uniform-style1:hover, a.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.button-default:hover {
  color: #FFF !important;
  border-color: #70EADC !important;
  background-color: #70EADC;
}

.font-face-AG{
  font-family: 'Poppins', sans-serif !important;
}

.font-face-LB{
  font-family: 'Poppins', sans-serif !important;
}

.heading1{
  font-size: 1.86em !important;
  letter-spacing: 5px !important;
}

.heading2{
  font-size: 3em !important;
}

.MuiAppBar-colorDefault {
  background-color: rgba(0,0,0,0.9) !important;
}

.bg-transparent{
  background-color: rgba(0,0,0,0.1) !important;
}

.bg-grey{
  background-color: rgba(45,45,45,0.8) !important;
}

.MuiPaper-root.MuiDrawer-paper.appbar-drawer.MuiDrawer-paperAnchorRight.MuiPaper-elevation16{
  background-color: transparent !important;
}

.PrivateRadioButtonIcon-root-35 {
  border-radius: 50%;
  background-color: #FFF !important;
  margin: 0px 1px 0px 5px;
}

.MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded {
  background-color: transparent;
}

.MuiPaper-root.grid-layout-item.noupdate.MuiPaper-elevation3.MuiPaper-rounded {
  background-color: transparent !important;
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  background-color:#FFF !important;
}

.MuiButton-outlinedPrimary {
  color: #70EADC;
  border: 1px solid rgb(255, 56, 56) !important;
}

.MuiButton-outlinedPrimary:hover {
  border: 1px solid #70EADC !important;
}

.MuiButton-containedPrimary {
  color: #FFF !important;
  background-color: rgba(255,255,255,0.0) !important;
  border: 1px solid rgb(255, 56, 56) !important;
}

.MuiButton-containedPrimary:hover {
  background-color: #70EADC !important;
  border: 1px solid #70EADC !important;
  color: #fff !important;
}

.MuiButton-disableElevation:hover {
  background-color: #79eadc !important;
}

.MuiMobileStepper-dots {
  display: none !important;
}

.logo-container img, .logo-container{
  height: 80px;
}

.product-button-container{
  position:absolute;
  top: 50%;
  left: 5%;
}

.product-button{
  font-size: 3em !important;
  border-radius: 15%;
}

.product-button:hover{
  background-color:#70EADC !important;
}

.apply-now-form{
  padding: 1em 7em;
}

span.MuiButtonBase-root.MuiIconButton-root.PrivateSwitchBase-root-80.MuiRadio-root.MuiRadio-colorPrimary.MuiIconButton-colorPrimary {
  color: #FFF !important;
}

@media only screen and (max-width: 1440px) {
  .navbar-profile-name{
    display: none;
  }

  .product-button-container{
    position:absolute;
    top: 50%;
    left: 5%;
  }
  
  .product-button{
    font-size: 3em !important;
    border-radius: 15%;
  }

  .product-button:hover{
    background-color:#70EADC !important;
  }
}

@media only screen and (max-width: 1025px) {
  .appbar-drawer {
    width: 35%;
  }

  .speech-bubble:after {
    margin-left: -80px;
  }

  .jss41{
    width:60px !important;
    height:60px !important;
  }

  .product-button{
    font-size: 2em !important;
    border-radius: 15%;
  }

  .apply-now-form {
    padding: 1em 5em;
  }

  .makeStyles-joinInputs-86 {
    width: 350px !important;
  }
}

@media screen and (max-width: 800px) {
  button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.banner-item-button.MuiButton-containedPrimary{
      font-size: small;
      bottom:40px !important;
  }

  .appbar-drawer {
    width: 45%;
  }

  .jss41 {
    width: 80px !important;
    height: 80px !important;
  }

  .product-button{
    font-size: 1.5em !important;
    border-radius: 15%;
  }

  .apply-now-label .apply-now-inner-container{
    position:inherit;
    margin:auto;
    text-align: center;
    top:0px;
  }
}

@media only screen and (max-width: 700px) {
  .appbar-drawer {
    width: 50%;
  }
}

@media only screen and (max-width: 500px) {
  .appbar-drawer {
    width: 50%;
  }
}

@media screen and (max-width: 425px) {
  button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.banner-item-button.MuiButton-containedPrimary{
      font-size: 0.6em;
      border-width:1.5px;
  }

  .testimonials-photo{
    width:100% !important;
    height: 220px !important;
  }

  .footer-logo{
    width:70% !important;
  }

  .video-container{
    width:300px;
  }

  .join-us-container{
    padding: 6em 4em !important;
    position:relative;
  }
  
  .join-form-container{
    width:100% !important;
  }

  .jss41 {
    width: 75px !important;
    height: 75px !important;
    margin: 0em 1em;
  }

  .speech-bubble:after {
    margin-left: -105px;
  }

  .copyrightInner{
    display:block !important;
    height: 55px;
  }

  .product-button{
    font-size: 0.7em !important;
    border-radius: 15%;
  }

  .apply-now-form{
    padding: 1em !important;
  }

  .makeStyles-loginForm-16{
    padding:0em 3.5em !important;
  }
}

@media screen and (max-width: 375px) {
  .video-container{
    width:270px;
  }

  .jss41 {
    width: 75px!important;
    height: 75px!important;
    margin: 0em 0.5em;
  }

  .speech-bubble:after {
    margin-left: -95px;
  }

  .product-button{
    font-size: 0.7em !important;
    border-radius: 15%;
  }

  .makeStyles-loginForm-16{
    padding:0em 0em !important;
  }

  .footer-logo{
    width:100px !important;
    padding: 0px !important;
  }
}

@media screen and (max-width: 320px) {
  .video-container{
    width:220px;
  }

  .apply-now-container{
    padding: 5em 2em !important;
  }

  .jss41 {
    width: 50px !important;
    height: 50px !important;
  }

  .footer-logo{
    width:100px !important;
  }

  .makeStyles-loginForm-16{
    padding:0em 0em !important;
  }
}

.MuiPaper-elevation16 {
  box-shadow: none !important;
}